import { useEffect, useRef, useState } from "react";
import "./MasonryCard.scss";
import { nameSlug } from "@/utils/Functions/NameSlug";
import Link from "next/link";
import { IoMdClose } from "react-icons/io";

interface MasonryCardI {
  status?: "approved" | "rejected" | "moderated";
  gallery: {
    year?: string;
    name?: string;
    avatar: any;
    photos?: number;
    gallery_name?: string;
  };
  location?: string;
}
function MasonryCard(props: MasonryCardI) {
  const { status = "", gallery } = props;
  const imageRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  let link = "";
  if (gallery.name) {
    if (gallery.gallery_name) {
      const { gallery_name } = gallery;
      if (gallery.year) {
        link = `/${nameSlug(gallery_name)}/${gallery.year}/0`;
      } else {
        link = `/${nameSlug(gallery_name)}`;
      }
    } else {
      const { name } = gallery;
      if (gallery.year) {
        link = `/${nameSlug(name)}/${gallery.year}/0`;
      } else {
        link = `/${nameSlug(name)}`;
      }
    }
  }
  const retrieveName = (name?: string) => {
    if (!name || name === "undefined") {
      if (gallery.gallery_name) {
        return gallery.gallery_name;
      } else {
        return "";
      }
    } else {
      return name;
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(imageRef.current!);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <Link href={link}>
      <div className={`recommend-gallery-card ${status + "-card"}`}>
        <img
          ref={imageRef}
          src={isVisible ? props.gallery?.avatar : ""}
          alt={gallery?.name || "photo"}
        />
        {link && (
          <div className="gallery-info">
            {props.location && props.location === "categories" && (
              <h2>{retrieveName(gallery?.name)}</h2>
            )}
            {!props.location && props.gallery?.name && (
              <h3>{retrieveName(gallery?.name)}</h3>
            )}
            {props.gallery?.photos && <h4>{props.gallery?.photos} images</h4>}
          </div>
        )}
      </div>
    </Link>
  );
}

export default MasonryCard;
