import toast from "react-hot-toast";
import Swal from "sweetalert";
import "./styles.scss";

export const notifySuccess = (message: string) => {
  toast.success(message, {
    className: "toast-notification",
    style: { background: "#0d8a0d", color: "#ffffff" },
    position: "top-right",
    duration: 4000,
  });
};

export const notifyError = (message: string) => {
  toast.error(message, {
    className: "toast-notification error",
    style: { background: "#a12727", color: "#ffffff" },
    position: "top-right",
    duration: 4000,
  });
};

export const notifyWarning = (message: string) => {
  toast(message, {
    className: "toast-notification",
    icon: "⚠️",
    style: {
      background: "#ffac4a",
      color: "#ffffff",
    },
    position: "top-right",
    duration: 4000,
  });
};
export const notifyAlert = (
  message: string,
  approved: number | null = null,
  rejected: number | null = null,
  moderated: number | null = null
) => {
  if (approved === 0) {
    approved = null;
  }
  if (rejected === 0) {
    rejected = null;
  }
  if (moderated === 0) {
    moderated = null;
  }
  Swal({
    text: `${message}${
      approved !== null ? `\nImages Approved: ${approved}` : ""
    }${rejected !== null ? `\nImages Rejected: ${rejected}` : ""} ${
      moderated !== null
        ? `\nImages are rejected, because they are already uploaded by someone else and still waiting for moderation: ${moderated}`
        : ""
    }`,
    icon: "info",
    className: "notification-alert",
    buttons: {
      confirm: {
        text: "Dismiss",
        value: true,
        visible: true,
        className: "",
        closeModal: true,
      },
    },
  });
};

type LocalStorage = "multipleUploadAlert" | "createGalleryAlert";
export const RegularAlert = (message: string, storageType: LocalStorage) => {
  Swal({
    icon: "info",
    className: "notification-alert",
    content: {
      element: "div",
      attributes: {
        innerHTML: message,
      },
    },
    buttons: {
      dontShowAgain: {
        text: "Don't show this again",
        value: "dontShowAgain",
        visible: true,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "Dismiss",
        value: true,
        visible: true,
        className: "",
        closeModal: true,
      },
    },
  }).then((value) => {
    if (value === "dontShowAgain") {
      localStorage.setItem(storageType, "true");
    }
  });
};
