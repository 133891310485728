"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
const questionIcon = "../../assets/images/icons/question-icon.svg";
export const QuestionIcon = () => {
  const pathname = usePathname();
  return (
    <Link href={"/about"}>
      <img
        src={questionIcon}
        className={`about-image-btn ${
          pathname.includes("gallery") ? "d-none" : ""
        }`}
        role="button"
      />
    </Link>
  );
};
