"use client";
import "./Navbar.scss";
import { useUserContext } from "../../context/UserContext";
import AuthModal from "../AuthModal/AuthModal";
import { FaChevronDown } from "react-icons/fa6";
import { useEffect, useRef, useState } from "react";
import LoginForm from "../AuthModal/forms/LoginForm/LoginForm";
import SignUpForm from "../AuthModal/forms/SignUpForm/SignUpForm";
import ForgotPasswordForm from "../AuthModal/forms/ForgotPasswordForm/ForgotPasswordForm";
import SearchBar from "../SearchBar/SearchBar";
import { MobileNavBar } from "../MobileNavBar/MobileNavBar";
import { getUserNotifications } from "@/services/NotificationsService";
import NotificationsContainer from "../Notifications/NotificationsContainer";
import { NotificationI } from "@/interfaces/Notifications";
import Inbox from "../Inbox/Inbox";
import { useChatContext } from "@/context/InboxContext";
import { handleOutsideClick } from "@/utils/Functions/General";
import { usePathname, useSearchParams } from "next/navigation";
import Link from "next/link";
const userAvatarDefault = "../../assets/images/placeholder-image.png";
const messageIcon = "../../assets/images/icons/message-icon.svg";
const burgerIcon = "../../assets/images/icons/navbar-menu.svg";
const notificationIcon = "../../assets/images/icons/bell-icon.svg";
const logo = "../../assets/images/LOGO.svg";
const witnessLogo = "../../assets/images/witness-logo.svg";
const lightMode = "../../assets/images/icons/toggle-icon-light.svg";
const darkMode = "../../assets/images/icons/toggle-icon.svg";
const userIcon = "../../assets/images/icons/user-gray-icon.svg";
const signOutIcon = "../../assets/images/icons/sign-out-icon.svg";
interface NavbarPropsI {
  theme: string;
  toggleTheme: () => void;
  googleApi: string;
}
const logoCeleb = "../../assets/images/kaleb-logo-background.svg";
function Navbar(props: NavbarPropsI) {
  const { theme, toggleTheme, googleApi } = props;
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { currentUser, handleLogin, handleLogout, userToken, userLoader } =
    useUserContext();
  const [authModal, setAuthModal] = useState({ show: false, form: "" });
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
  const [burgerMenu, setBurgerMenu] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const dropdownRef: any = useRef(null);
  const burgerRef: any = useRef(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Array<NotificationI>>([]);
  const [pendingNotifications, setPendingNotifications] =
    useState<boolean>(false);
  const [referralId, setReferralId] = useState<string>("");
  const { pendingChats, openInbox, setOpenInbox, inboxContainerRef } =
    useChatContext();
  const notificationsRef = useRef<any>(null);
  const notificationNavBtn = useRef<any>(null);
  const inboxNavBtnRef = useRef<any>(null);
  const responsiveUserMenuRef = useRef<any>(null);
  const [windowWidth, setWindowWidth] = useState<number>(1000);
  const getAllNotifications = async () => {
    if (currentUser) {
      let tempNotifs = await getUserNotifications(userToken, currentUser._id);
      checkForUnseenNotifs(tempNotifs);
      setNotifications(tempNotifs);
    }
  };
  const checkForUnseenNotifs = (notifs: Array<NotificationI>) => {
    const hasNewElement = notifs.some((obj: NotificationI) => obj.isNewElement);
    setPendingNotifications(hasNewElement);
  };

  const handleOutsideClickNotifications = (event: any) =>
    handleOutsideClick(
      event,
      notificationNavBtn,
      setShowNotifications,
      notificationsRef
    );
  const handleOutsideClickInbox = (event: any) =>
    handleOutsideClick(event, inboxContainerRef, setOpenInbox, inboxNavBtnRef);
  const handleOutsideClickUserMenu = (event: any) =>
    handleOutsideClick(event, dropdownRef, setBurgerMenu, burgerRef);

  const checkReferralLink = () => {
    const params = new URLSearchParams(searchParams);
    if (params.has("referral") && !currentUser) {
      let tempRefId = params.get("referral");
      setReferralId(tempRefId ?? "");
      setAuthModal({ show: true, form: "signUp" });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickNotifications);
    document.addEventListener("mousedown", handleOutsideClickInbox);
    document.addEventListener("mousedown", handleOutsideClickUserMenu);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleOutsideClickNotifications
      );
      document.removeEventListener("mousedown", handleOutsideClickInbox);
      document.removeEventListener("mousedown", handleOutsideClickUserMenu);
    };
  }, []);

  useEffect(() => {
    currentUser && getAllNotifications();
    !userLoader && !currentUser && checkReferralLink();
  }, [currentUser, userLoader]);

  useEffect(() => {
    setBurgerMenu(false);
  }, [searchParams]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <AuthModal
        show={authModal.show}
        onHide={() => setAuthModal({ show: false, form: "" })}
        theme={theme}
      >
        {authModal.form === "login" && (
          <LoginForm
            authModal={authModal}
            setAuthModal={setAuthModal}
            handleLogin={handleLogin}
            googleApi={googleApi}
          />
        )}
        {authModal.form === "signUp" && (
          <SignUpForm
            authModal={authModal}
            setAuthModal={setAuthModal}
            referralId={referralId}
            googleApi={googleApi}
          />
        )}
        {authModal.form === "forgotPassword" && (
          <ForgotPasswordForm
            authModal={authModal}
            setAuthModal={setAuthModal}
          />
        )}
      </AuthModal>
      {currentUser && (
        <NotificationsContainer
          notificationsRef={notificationsRef}
          setShowNotifications={setShowNotifications}
          show={showNotifications}
          notifications={notifications}
          setNotifications={setNotifications}
          setPendingNotifications={setPendingNotifications}
        />
      )}
      <div className="relative-container">
        {openInbox && currentUser && <div className="triangle" />}
        {currentUser && <Inbox />}
        {windowWidth <= 992 && (
          <MobileNavBar
            show={showSearch}
            inboxNavBtnRef={inboxNavBtnRef}
            notificationNavBtn={notificationNavBtn}
            setShowSearchBar={setShowSearch}
            setShowNotifications={setShowNotifications}
            showNotifications={showNotifications}
          />
        )}
      </div>
      <nav className={theme}>
        <img
          className="toggle-theme-btn"
          src={theme === "dark" ? lightMode : darkMode}
          alt="toggle theme"
          onClick={toggleTheme}
        />
        <div className="images-container">
          <div className="home">
            <Link className={pathname === "/" ? "active" : ""} href={"/"}>
              <img src={logoCeleb} className="logo-background" />
              <img src={logo} alt="kalebGlory logo" />
            </Link>
          </div>
          <div className="witness">
            <img src={witnessLogo} alt="make them witness" />
          </div>
        </div>
        <SearchBar show={showSearch} />
        <div className="nav-options-container">
          <ul>
            <li className="nav-item nft">
              <Link href={"/NFTprogram"}>NFT Program</Link>
            </li>
            <li className="nav-item">
              <Link
                className={pathname === "/about" ? "active" : ""}
                href={"/about"}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={pathname === "/categories" ? "active" : ""}
                href={"/categories"}
              >
                Categories
              </Link>
            </li>
            {currentUser === null ? (
              <>
                <li
                  className="nav-button btn linear-btn"
                  onClick={() => setAuthModal({ show: true, form: "login" })}
                >
                  Login
                </li>
                <li
                  className="nav-button btn linear-btn"
                  onClick={() => setAuthModal({ show: true, form: "signUp" })}
                >
                  Sign Up
                </li>
              </>
            ) : (
              <>
                <li
                  className="notification-btn"
                  ref={notificationNavBtn}
                  onClick={() => setShowNotifications(!showNotifications)}
                >
                  <img src={notificationIcon} alt="bell icon" />
                  {pendingNotifications && <div className="seen-check"></div>}
                </li>
                <li
                  onClick={() => setOpenInbox(!openInbox)}
                  ref={inboxNavBtnRef}
                >
                  {pendingChats > 0 && (
                    <div className="pending-chats-circle rounded-circle stem-light m-0 p-0">
                      {pendingChats}
                    </div>
                  )}
                  <img src={messageIcon} alt="mail icon" />
                </li>
                <li
                  ref={burgerRef}
                  className="profile-btn"
                  onClick={() => setBurgerMenu(!burgerMenu)}
                >
                  <div className="profile-pic-container">
                    <img
                      src={
                        currentUser.avatar !== "none"
                          ? currentUser.avatar
                          : userAvatarDefault
                      }
                      alt={currentUser.name}
                    />
                  </div>
                  <FaChevronDown />
                </li>
                <div
                  ref={dropdownRef}
                  className={`user-dropdown ${burgerMenu && "show"}`}
                >
                  <Link href={"/profile"} role="button">
                    <img src={userIcon} alt="user" />
                    <p>My profile</p>
                  </Link>
                  {currentUser.admin > 0 && (
                    <Link
                      href={currentUser.admin === 3 ? "/admin/users" : "/admin"}
                      role="button"
                    >
                      <img src={userIcon} alt="user" />
                      <p>Administrator</p>
                    </Link>
                  )}
                  <div
                    role="button"
                    className="sign-out"
                    onClick={handleLogout}
                  >
                    <img src={signOutIcon} alt="user" />
                    <p>Sign out</p>
                  </div>
                </div>
              </>
            )}
          </ul>
          {windowWidth <= 992 && (
            <div
              className={"burger-menu " + (burgerMenu ? "disable" : "")}
              ref={burgerRef}
              onClick={() => setBurgerMenu(!burgerMenu)}
            >
              <img src={burgerIcon} alt="menu" />
            </div>
          )}
        </div>
        {/* <div className="notifications-navbar-container"></div> */}
        {windowWidth <= 992 && (
          <div
            className={`responsive-nav-content ${burgerMenu ? "open" : ""}`}
            ref={dropdownRef}
          >
            <ul>
              <li>
                <Link href={"/about"}>About</Link>
              </li>
              {currentUser && currentUser.admin !== 0 && (
                <li>
                  <Link href={"/admin"}>Admin</Link>
                </li>
              )}

              <li>
                <Link href={"/categories"}>Categories</Link>
              </li>
              <li>
                <Link href={"/NFTprogram"}>NFT Program</Link>
              </li>
              <li>
                <Link href={"/upload"}>Upload</Link>
              </li>
              {!currentUser ? (
                <>
                  <li
                    className="nav-button btn linear-bg"
                    style={{ width: 200, borderRadius: 3 }}
                    onClick={() => setAuthModal({ show: true, form: "login" })}
                  >
                    Login
                  </li>
                  <li
                    className="nav-button btn linear-bg outline-bg"
                    style={{ width: 200, borderRadius: 3 }}
                    onClick={() => setAuthModal({ show: true, form: "signUp" })}
                  >
                    Sign Up
                  </li>
                </>
              ) : (
                <div
                  role="button"
                  className="btn linear-bg"
                  style={{ width: 200, borderRadius: 3 }}
                  onClick={handleLogout}
                >
                  <p className="m-0">Sign out</p>
                </div>
              )}
              <li>
                <img
                  className=""
                  src={theme === "dark" ? lightMode : darkMode}
                  alt="toggle theme"
                  onClick={toggleTheme}
                />
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
}

export default Navbar;
