export function nameSlug(name: string) {
  const encodedActivityName = encodeURIComponent(name);
  // return name
  // .toLowerCase()
  // .replace(/\s+/g, "-")
  // .replace(/[^a-z0-9-]/gi, "")
  // .replace(/-+/g, "-");
  return encodedActivityName;
}
export function revertNameSlug(name: string) {
  const decodedName = decodeURIComponent(name);
  return decodedName;
  // if (!name) return "";
  // return name
  //   .replaceAll("00", "’")
  //   .replaceAll("11", ".")
  //   .replace(/-/g, " ") // Convert hyphens back to spaces
  //   .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function MakeUpperCase(str: string) {
  const mLC = str.toLowerCase();
  return mLC.charAt(0).toUpperCase() + mLC.slice(1);
}
