import "./Inbox.scss";
import { useChatContext } from "@/context/InboxContext";
import RecentsChats from "./components/RecentsChats/RecentsChats";
import NewMessage from "./components/NewMessage/NewMessage";
import Chat from "./components/Chat/Chat";

function Inbox() {
  const { chatView, openInbox, inboxContainerRef } = useChatContext();
  return (
    <div
      className={`chat-component-main-container ${openInbox ? "" : "d-none"}`}
      ref={inboxContainerRef}
    >
      {chatView === 0 && <RecentsChats />}
      {chatView === 1 && <NewMessage />}
      {chatView === 2 && <Chat />}
    </div>
  );
}

export default Inbox;
